<template>
  <router-link
    v-on:click.native="onClick"
    :class="[tabStatus]"
    :to="{ name: menuSetting.component }"
  >
    <img
      class="svg"
      :src="this.menuSetting.image"
      :alt="menuSetting.alt"
    />
    <span class="menu_content">{{ menuSetting.title }}</span>
  </router-link>
</template>

<script>
  export default {
    props: {
      activetab: {
        Number,
        default: -1
      },
      menuSetting: {
        Object,
        required: true
      },
    },
    computed: {
     tabStatus() {
       return this.activetab === this.menuSetting.tab ? 'active' : ''
     },
    },
    methods: {
      onClick() {
        this.$emit('clicked', this.menuSetting.tab)
      }
    },
  }
</script>

<style lang="scss" scoped></style>


