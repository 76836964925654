import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/style.scss'
import VueAnalytics from 'vue-analytics';

Vue.use(CoolLightBox);
Vue.use(VueAnalytics, {
  id: 'UA-219512080-1',
  router
})
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
