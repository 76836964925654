<template>
  <div>
    <div class="tokyo_tm_home">
      <div class="home_content">
        <div class="avatar">
          <div
            class="image avatar_img"
            :style="{ backgroundImage: 'url(' + src1 + ')' }"
          ></div>
        </div>
        <!-- End .avatar -->
        <div class="details">
          <h3 class="name">Toaster Artist</h3>
          <p class="job">
            Artist, publisher, software engineer based in Texas.
          </p>

          <Social />

        </div>
        <!-- End .details -->

      </div>
    </div>
    <Subscribe />
  </div>
</template>

<script>
  import Social from "../components/Social"
  import Subscribe from "../components/Subscribe"

  export default {
    components: {
      Social,
      Subscribe
    },
    data() {
      return {
        src1: require("../assets/img/slider/1.jpg"),
      };
    },
  };
</script>

<style lang="scss"></style>
