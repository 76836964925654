<template>
  <div id="app" class="tokyo_tm_all_wrap">
    <div class="dark">
      <div>
        <!-- Start leftpart -->
        <div class="leftpart">
          <div class="leftpart_inner">
            <div class="logo">
              <router-link to="/"
                ><img src="./assets/img/logo/dark.png" alt=""
              /></router-link>
            </div>
            <!-- End .logo -->
            <div class="menu">
              <MenuItem
                v-for="(menuSetting, menuSettingIndex) in menuSettings"
                :key="menuSettingIndex"
                :menu-setting="menuSetting"
                @clicked="updateActivetab"
                :activetab="activetab"
              />
            </div>
            <Copyright />
          </div>
        </div>
        <!-- End leftpart -->

        <!-- Start rightpart -->
        <div class="rightpart">
          <div class="rightpart_in">
            <div class="tokyo_tm_section" id="mainpart">
              <div class="container">
                <router-view />
              </div>
            </div>
          </div>
        </div>
        <!-- End rightpart -->
      </div>
    </div>
  </div>
</template>

<script>
  let ROOT_PATH = 'https://www.toasterartist.com'
  import MenuItem from "./components/MenuItem";
  import Copyright from "./components/Copyright";
  import { find } from "lodash"

  export default {
    components: {
      MenuItem,
      Copyright,
    },
    metaInfo() {
      return {
        title: 'Toaster Artist',
        meta: [
          // Facebook OpenGraph
          {property: 'og:title', content: 'Toaster Artist Books'},
          {property: 'og:site_name', content: 'Toaster Artist'},
          {property: 'og:type', content: 'website'},
          {property: 'og:image', content:  this.metaImage},
          {property: 'og:description', content: 'Logs, Journals and more as found on Amazon'}
        ]
      }
    },
    data() {
      return {
        metaImage: ROOT_PATH + require('./assets/img/logo/logo.png'),
        activetab: 1,
        menuSettings: [
          {
            image: require('./assets/img/svg/home-run.svg'),
            title: 'Home',
            tab: 1,
            alt: 'homerun',
            component: 'Home',
            basePaths: ['/']
          },
          {
            image: require('./assets/img/svg/palette-solid.svg'),
            title: 'Art',
            tab: 5,
            alt: 'briefcase',
            component: 'Art',
            basePaths: ['/art']
          },
          {
            image: require('./assets/img/svg/book-open-solid.svg'),
            title: 'Books',
            tab: 4,
            alt: 'briefcase',
            component: 'BookLists',
            basePaths: ['/book-lists', '/book-versions']
          },
          {
            image: require('./assets/img/svg/avatar.svg'),
            title: 'About',
            tab: 2,
            alt: 'avatar',
            component: 'About',
            basePaths: ['/about']
          },
          // {
          //   image: require('./assets/img/svg/paper.svg'),
          //   title: 'News',
          //   tab: 6,
          //   alt: 'paper',
          //   component: 'News',
          // },
          {
            image: require('./assets/img/svg/mail.svg'),
            title: 'Contact',
            tab: 7,
            alt: 'mail',
            component: 'Contact',
            basePaths: ['/contact']
          },
        ]
      };
    },
    methods: {
      updateActivetab(tab) {
        this.activetab = tab
      },
      isActive(tab) {
        return tab === this.activetab
      }
    },
    watch: {
      $route(to,) {
        let regex = /(\/[a-z|-]+).*/
        let regexResult = regex.exec(to.path)

        if(regexResult == null) {
          this.activetab = 1
        } else {
          let setting = find(this.menuSettings, (ms) => find(ms.basePaths, (bp) => bp === regexResult[1] ) )

          this.activetab = setting.tab
        }

        //const layoutId = document.getElementById('layout-scroll-container');
        const layoutId = document.getElementById('mainpart');

        if (layoutId) {
            layoutId.scrollTop = 0;
        }
      }
    },
  };
</script>

<style lang="scss" scoped></style>
