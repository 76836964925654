import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '../views/NotFound.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import("../views/About.vue"),
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: () =>
      import("../views/Portfolio.vue"),
  },
  {
    path: "/books/",
    redirect: '/book-lists/1',
    name: "BookLists",
    component: () =>
      import("../views/Books.vue")
  },
  {
    path: "/book-lists/",
    redirect: '/book-lists/1',
    name: "BookLists",
    component: () =>
      import("../views/Books.vue")
  },
  {
    path: "/book-lists/:tab",
    component: () =>
      import("../views/Books.vue")
  },
  {
    path: "/book-versions/:title",
    component: () =>
      import("../views/BookVersions.vue")
  },
  {
    path: "/art",
    name: "Art",
    component: () =>
      import("../views/Art.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () =>
      import("../views/News.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import("../views/Contact.vue"),
  },
  // {
  //   path: "/links",
  //   name: "Links",
  //   meta: {
  //     title: 'Links'
  //   },
  //   component: () =>
  //     import("../views/Links.vue"),
  // },
  {
    path: "/subscribe",
    component: () =>
      import("../views/JoinList.vue")
  },
  {
    path: '*',
    name: NotFound,
    component: () => import("../views/NotFound.vue")
  }
];
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router;
